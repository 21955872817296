import React from 'react';

const renderImages = (button, buttonStyle, hoverAnimation, image) => {
    let desktopImage = (image && image.desktopImage && image.desktopImage.gatsbyImageData)
    ? image.desktopImage.gatsbyImageData.images.fallback.src
    : (image && image.desktopImage && image.desktopImage.file)
    ? image.desktopImage.file.url
    : '';

    const desktopImageSrcSet = (image && image.desktopImage && image.desktopImage.gatsbyImageData)
    ? image.desktopImage.gatsbyImageData.images.fallback.srcSet
    : '';

    const mobileImage = (image && image.mobileImage && image.mobileImage.gatsbyImageData) 
    ? <img className="img mobile animated" 
    src={image.mobileImage.gatsbyImageData.images.fallback.src}
    srcSet={image.mobileImage.gatsbyImageData.images.fallback.srcSet}
    alt={image ? image.altText : ''} 
    /> 
    : 
    desktopImage ? 
    <img className="img mobile animated" 
    src={desktopImage}
    srcSet={desktopImageSrcSet}
    alt={image ? image.altText : ''} 
    style={{maxWidth: image.imageMaxWidth ? image.imageMaxWidth + 'px' : ''}}
    />
    : '';

    const hoverImage = desktopImage && (hoverAnimation !== 'no-animation')
    ? <img className={`img hover animated ${hoverAnimation}`}
    src={desktopImage}
    srcSet={desktopImageSrcSet}
    alt={image ? image.altText : ''}
    style={{maxWidth: image.imageMaxWidth ? image.imageMaxWidth + 'px' : ''}}
    /> 
    : '';
    
    desktopImage = desktopImage
    ? <img className="img desktop animated" 
    src={desktopImage}
    srcSet={desktopImageSrcSet}
    alt={image ? image.altText : ''}
    style={{maxWidth: image.imageMaxWidth ? image.imageMaxWidth + 'px' : ''}}
    /> 
    : '';

    const Container = desktopImage
    ? <div data-image>
        {desktopImage}
        {mobileImage}
        {hoverImage}
        { button ? button : '' }
    </div>
    : '';
    return Container;
}

export default renderImages