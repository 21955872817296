import React, { useEffect } from "react";
import { scrollAnimation, genTextColor, SlickGalleryHeight, getWindowDimensions } from '../../../helpers/general'
import Breadcrumbs from '../../../components/atoms/Breadcrumbs/Breadcrumbs'
import './ContentfulGlobal.css'
import * as styles from './ContentfulPageLayout.module.css';
import renderSection from './renderSection'

const PageLayout = ({pageName, section, sectionIndex, paddingTopBottom, breadCrumbs, logoStyle, className , customTextColor}) => {
const backgroundColour = section.backgroundColour ? '#'+section.backgroundColour : '#ffffff';
const textColour = section.textColour && section.textColour.textColour ? section.textColour.textColour.textColour : '';
const sectionId = section.name ? section.name.trim().replaceAll('.', '').replace(/[^A-Z0-9]+/ig, "-").toLowerCase() : '';
const sectionBlockWidth = (section && section.blockWidth && section.blockWidth.blockWidth.blockWidth) || false;
const windowDimensions = getWindowDimensions();
const paddingSet = (windowDimensions['width'] > 801) && (sectionIndex !== 0)
? `${paddingTopBottom}px` 
: (windowDimensions['width'] < 800) && (sectionIndex !== 0) && (paddingTopBottom >= 40)
? '40px'
: '';
const contentHeroClass = (sectionIndex === 0 && logoStyle === 'black-with-padding-top')
? ' add-padding-top'
: ''

const Content = 
    <div id={`${sectionId}-${sectionIndex}`} 
    data-key={sectionIndex}
    className={`${sectionId}${contentHeroClass}`} 
    data-block-name={section.name ? section.name.trim() : ''}
    data-text-color={textColour || customTextColor} 
    data-block=
        {
            section && section.internal
            ? section.internal.type
            : ''
        }
    style={{paddingTop: paddingSet, paddingBottom: paddingSet, backgroundColor: backgroundColour}}
    >
        {sectionIndex === 0 && breadCrumbs === true
        ? 
        <div data-breadcrumbs data-width={sectionBlockWidth}>
        <Breadcrumbs crumbs={[{link: '/', label: 'Home'}, {label: pageName}]} />
        </div>
        : ''
        }
        <div data-contentblock key={sectionIndex}
        data-block={
            section && section.internal
            ? section.internal.type
            : ''
        }
             className={className}
        >
            {renderSection(sectionId, sectionIndex, section)}
        </div>
    </div>
;

useEffect(() => {
    window.addEventListener('scroll', (e) => {
        scrollAnimation(e, sectionId, sectionIndex);
    });
    genTextColor();
    SlickGalleryHeight();
}, [sectionId, sectionIndex]);
    
  return (
    <div className={styles.layout} data-component>
        {Content}
    </div>
  )
}

export default PageLayout