import React from 'react';
import parse from 'html-react-parser';
import { gentHTML, scrollToSection } from '../../../helpers/general';
import renderImages from './renderImages';
import Icon from '../../atoms/Icon/Icon';

const renderContent = (type, blockWidth, content) => {
// console.log((content && content.blockWidth ? content.blockWidth.blockWidth.blockWidth : ''), `renderContent Data Test | ${type} => `, type, content)
    const blockAlignment = content && content.blockAlignment ? content.blockAlignment.blockAlignment : '';
    const textAlignment = content && content.textAlignment ? parse(content.textAlignment.textAlignment) : '';
    const textColour = content.textColour ? content.textColour.trim().replaceAll('#', '') : '';
    const heading = content && content.heading ? parse(content.heading) : '';
    const headingSize = content && content.headingSize ? parse(content.headingSize.headingSize) : '';
    const dataContent = content && content.childContentfulBlockContentContentTextNode
    ? parse(content.childContentfulBlockContentContentTextNode.childMarkdownRemark.html) 
    : content && content.content && content.content.childMarkdownRemark
    ? parse(gentHTML(content.content.childMarkdownRemark.html)) 
    : '';
    const buttonStyle = content && content.buttonStyle ? content.buttonStyle.buttonStyle : '';
    const imagePosition = content && content.imagePosition ? content.imagePosition.imagePosition : '';
    const hoverAnimation = content.imageHoverAnimation ? content.imageHoverAnimation.imageHoverAnimation.trim().replaceAll('.', '').replace(/[^A-Z0-9]+/ig, "-").toLowerCase() : '';

    const buttonUrl = content && content.buttonUrl ? content.buttonUrl : '';
    const button = (content && content.buttonName && buttonUrl) && (buttonStyle !== 'button deep link 1')
    ?
    <a className='btn btn-cta' 
    href={content.buttonUrl} 
    data-button={buttonStyle}>
    {buttonStyle === 'button 4'
    ? <Icon symbol="iconArrow" /> 
    : ''
    }
    {content.buttonName}
    </a>
    : (content && content.buttonName && buttonUrl) && (buttonStyle === 'button deep link 1')
    ? <div className='btn btn-cta' data-button={buttonStyle} data-deeplink={buttonUrl} onClick={scrollToSection} aria-hidden='true'>
    <span className='txt'>{content.buttonName}</span><Icon symbol="iconArrow" />
    </div>
    : '';
    

    const renderImg = content && content.backgroundImage
    ? renderImages((buttonStyle === 'button 5 hover only' ? button : ''), buttonStyle, hoverAnimation, content.backgroundImage)
    : '';

    let Container = renderImg;
    Container =
    <div className="bx-content" data-width={blockWidth} data-container data-alignment-content={textAlignment} data-image-possition={imagePosition} data-text-color={textColour} data-blockalignment={blockAlignment}>
        {(
        button === '' && buttonUrl !== ''
        ? <a className="btn btn-full-link" href={buttonUrl}>{Container}</a>
        : Container
        )}
        <div data-content>
        <h3 data-title data-headingsize={headingSize}>{heading}</h3>
        <div data-text-content>{dataContent}</div>
        {(
        buttonStyle !== 'button 5 hover only'
        ? button
        : ''
        )}
        </div>
    </div>
    return Container;
}

export default renderContent