import React from 'react'
import parse from 'html-react-parser';
import Slider from '../Slider/Slider';
// import VimeoPlayer from './VimeoPlayer';
import { radioClass, scrollToSection, addClass, removeClass, vimeoPlay, vimeoPause, gentHTML, ButtonLoadMore, ButtonRadio } from '../../../helpers/general';
import renderImages from './renderImages';
import renderContent from './renderContent';
import Icon from '../../atoms/Icon/Icon';

const functionOnClick = (e) => {
    radioClass(e);
    scrollToSection(e);
}
const functionVimeoClose = (e) => {
    removeClass(e); 
    vimeoPause(e);
}

const functionVimeoClick = (e) => {
    addClass(e);
    vimeoPlay(e);
}

const renderSection = (sectionId, sectionIndex, section , pageName = '') => {
  let Container; let animation; let blockWidth; 
  const genID = `${sectionId}-${sectionIndex}`;
  const sectionType = section.internal && section.internal.type ? section.internal.type : '';
  const blockAlignment = section.blockAlignment && section.blockAlignment.blockAlignment ? section.blockAlignment.blockAlignment : '';
  const blockStyle = section.blockStyle && section.blockStyle.blockStyle ? section.blockStyle.blockStyle : '';
  const paddingTopBottom = section.paddingTopBottom ? section.paddingTopBottom.paddingTopBottom.paddingTopBottom + 'px' : '';
  switch(sectionType) {
    case 'ContentfulBlockSlides':
        const Heading = section && section.heading ? section.heading : '';
        const slideToShow = section && section.slideToShow ? parseInt(section.slideToShow.slideToShow) : 1;
        const slideStyle = section && section.slideStyle ? section.slideStyle.slideStyle : '';
        const centerMode = slideStyle === 'gallery' ? true : false;
        const centerPadding = (slideStyle === 'gallery') ? '40px' : '';
        const backgroundColour = section.backgroundColour ? `#${section.backgroundColour.replace('#', '')}` : '';
        const slideAutoplay = section && section.autoPlay ? true : false;
        const slideSpeed = section && section.slideSpeed ? section.slideSpeed : 2000;
        Container = section.slide.map((slide, slideIndex) => (
            <div data-slide  data-container data-video-vimeo={slide.vimeoId ? slide.vimeoId : ''} data-video={slide.vimeoId ? true : false}>
            <div data-images className={'image-container'} style={{maxWidth: slide.image && slide.image.imageMaxWidth ? slide.image.imageMaxWidth + 'px' : ''}}>
            {(slide.image && slide.image.desktopImage 
            ? <img class="img background" alt={slide.image ? slide.image.altText : ''} 
            src={slide.image.desktopImage.gatsbyImageData.images.fallback.src} srcset={slide.image.desktopImage.gatsbyImageData.images.fallback.srcSet} 
            /> : '')}
            {(slide.image && slide.image.desktopImage 
            ? <img class="img desktop" alt={slide.image ? slide.image.altText : ''} 
            src={slide.image.desktopImage.gatsbyImageData.images.fallback.src} srcset={slide.image.desktopImage.gatsbyImageData.images.fallback.srcSet} 
            /> 
            : '')}
            {(slide.image && slide.image.mobileImage 
            ? <img class="img mobile" alt={slide.image ? slide.image.altText : ''} 
            src={slide.image.mobileImage.gatsbyImageData.images.fallback.src}  srcset={slide.image.mobileImage.gatsbyImageData.images.fallback.srcSet} 
            /> 
            : 
            (slide.image && slide.image.desktopImage
            ? <img class="img mobile" alt={slide.image ? slide.image.altText : ''} 
            src={slide.image.desktopImage.gatsbyImageData.images.fallback.src} srcset={slide.image.desktopImage.gatsbyImageData.images.fallback.srcSet} 
            />
            : ''
            )
            )}
            </div>
            {(
            slide.vimeoId
            ?
            <iframe class="item-iframe animated" title={`iframe-${genID}-${slideIndex}`} src={`https://player.vimeo.com/video/${slide.vimeoId}?background=1&amp;autoplay=1&amp;loop=1&amp;title=0&amp;muted=1`} frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen=""></iframe>
            : ''
            )}
            <div className={'data-contents-container'}>
            <div data-contents style={{backgroundColor: slide.backgroundColor}}>
            {(slide.heading ? <h2 class="heading" data-title> {parse(gentHTML(slide.heading))}  </h2> : '')}
            {(slide.subText && slide.subText.childMarkdownRemark
            ? <div className='subText' data-text> {parse(gentHTML(slide.subText.childMarkdownRemark.html))}  </div> 
            : ''
            )}
              {(slide.coloredSubText) && <div style={{color: `#${slide.coloredSubText.textColor}`}}>{slide.coloredSubText.subText}</div>}
            {(
            slide && slide.button && slide.button.buttonText && slide.button.url
            ?
            <a className="btn btn-cta btn-4" href={slide.button.url}>
            <Icon symbol="iconArrow" />
            <span class="txt">{slide.button.buttonText}</span>
            </a>
            : 
            slide && slide.button && (slide.button.buttonText === '' || slide.button.buttonText === null) && slide.button.url
            ?
            <a className="btn btn-cta btn-full-link" href={slide.button.url}>
            {slide.button.buttonText}
            </a>
            : 
            ''
            )}
            </div>
            {slideStyle === 'hero - center - button next'
            ? <div className='btn icon icon-arrow-next'>
            <span className='txt' style={{color: backgroundColour}}>Next</span>
            </div>
            : ''
            }
            </div>
            {slideStyle === 'image background with arrow icon top'
            ? <div className='icon icon-arrow-top'><Icon symbol="iconArrow" /></div>
            : ''
            }
            </div>
        ))

        Container = <div data-slides={slideStyle} 
        data-width={section.blockWidth ? section.blockWidth.blockWidth.blockWidth : ''} 
        data-alignment-content={section.textAlignment ? section.textAlignment.textAlignment : ''}
        className={section.classes}
        >
        <div data-heading>
        {(Heading
        ? <h4>{Heading}</h4>
        : ''
        )}
        {(section.buttonName && section.buttonUrl 
        ? <a class="btn btn-cta btn-4" href={section.buttonUrl}><span class="txt">{section.buttonName}</span><Icon symbol="iconArrow" /></a>
        : ''
        )}
        </div>
        <Slider autoplaySpeed={slideSpeed} dots={false} customArrows={false} autoplay={slideAutoplay} slidesToShow={slideToShow} centerMode={centerMode} centerPadding={centerPadding}>
        {Container}
        </Slider>
        {slideStyle === 'gallery'
        ? <div className={'btn-container'}>
            <a class="btn btn-cta btn-5" href={section.buttonUrl}><span class="txt">Drag</span></a>
        </div>
        : ''
        }
        </div>
        return Container;
    case 'ContentfulBlockSingleCard':
        Container = 
        <div className={section.internal.type} data-card 
        data-width={section.content.blockWidth ? section.content.blockWidth.blockWidth.blockWidth : ''} 
        data-blockalignment={blockAlignment}
        data-video-vimeo-id={section.vimeoId} data-video={section.vimeoId ? true : false}
        >
        {renderContent(section.internal.type, 'full width', section.content)}
        {(
        section.vimeoId
        ?
        <div data-btn>
            <div class="btn btn-play" 
            data-addclass={`video-${sectionIndex}-${section.vimeoId}`} 
            data-video-vimeo-id={section.vimeoId}
            data-video-vimeo={`iframe-${sectionIndex}-${section.vimeoId}`}
            onClick={functionVimeoClick} aria-hidden='true'>
            </div>
        </div>
        : ''
        )}
        {(
        section.vimeoId
        ?
        <div id={`video-${sectionIndex}-${section.vimeoId}`} data-video-popup class="animated">
            <div class="bg dark" 
            data-removeClass={`video-${sectionIndex}-${section.vimeoId}`} 
            data-video-vimeo-id={section.vimeoId} 
            data-video-vimeo={`iframe-${sectionIndex}-${section.vimeoId}`}
            onClick={functionVimeoClose}
            aria-hidden='true'
            ></div>
            <div class="btn btn-close"
            data-removeClass={`video-${sectionIndex}-${section.vimeoId}`} 
            data-video-vimeo-id={section.vimeoId} 
            data-video-vimeo={`iframe-${sectionIndex}-${section.vimeoId}`}
            onClick={functionVimeoClose}
            aria-hidden='true'
            ></div>
            <div data-video-vimeo-id={section.vimeoId} id={`iframe-${sectionIndex}-${section.vimeoId}`}></div>
        </div>
        : ''
        )}
        </div>
        return Container;
    case 'ContentfulBlockDoubleCards':
        const mobileRevert = section && section.mobileRevert === true ? true : false;
        // console.log(section.textColour);
        Container =
        <div className={section.internal.type} data-card 
        data-width={section.blockWidth ? section.blockWidth.blockWidth.blockWidth : ''} 
        data-blockalignment={blockAlignment}
        data-mobile-revert={mobileRevert}
        data-bgimage={section.backgroundImage? true : false}
        style={{paddingTop: paddingTopBottom}}
        >
        {/*{section && section.backgroundImage ? <div data-bg-image className={'image-container'}>{renderImages('', '', 'no-animation', section.backgroundImage)}</div> : '' }*/}
          {section.title ? <h3 className={`section-title ${!!section.textColour && section.textColour.textColour.textColour}`}>{section.title}</h3> : ''}
          <div data-contents className='bx-contents'>

        <div className='bx-left bx-item first'
        data-blockalignment={section && section.contentLeft && section.contentLeft.blockAlignment ? section.contentLeft.blockAlignment.blockAlignment : ''}
        >
        {renderContent(section.internal.type,
        (section.contentLeft && section.contentLeft.blockWidth ? section.contentLeft.blockWidth.blockWidth.blockWidth : ''), 
        (section && section.contentLeft ? section.contentLeft : ''))}
        </div>
        <div className='bx-right bx-item last'
        data-blockalignment={section && section.contentRight && section.contentRight.blockAlignment ? section.contentRight.blockAlignment.blockAlignment : ''}
        >
        {renderContent(section.internal.type, 
        (section && section.contentRight && section.contentRight.blockWidth ? section.contentRight.blockWidth.blockWidth.blockWidth : ''), 
        (section && section.contentRight ? section.contentRight : ''))}
        </div>
        </div>
        </div>
        
        return Container;
    case 'ContentfulBlockTiles':
        animation = section.animation ? section.animation.animation.animation : '';
        Container = 
        <div className={section.internal.type} data-card data-width={section.blockWidth ? section.blockWidth.blockWidth.blockWidth : ''} data-blockalignment={blockAlignment} data-animation={animation}>
        {animation === 'parallax scrolling'
        ? <div data-background data-mobile>
                {
                section.tile.map((tile, tileIndex) => (
                    <div data-index={tileIndex} data-blockstyle={blockStyle}>
                        {
                        tile && tile.content
                        ? <div data-blocktype="single-card">{renderContent(section.internal.type, 'full width', tile.content ? tile.content : '')}</div>
                        : ''
                        }
                        {
                        tile && tile.contentLeft
                        ? <div data-blocktype="double-cards">{renderContent(section.internal.type, 'full width', tile.contentLeft ? tile.contentLeft : '')}</div>
                        : ''
                        }
                        {
                        tile && tile.contentRight
                        ? <div data-blocktype="double-cards">{renderContent(section.internal.type, 'full width', tile.contentRight ? tile.contentRight : '')}</div>
                        : ''
                        }
                    </div>
                ))
                }
        </div>
        : ''
        }
        {animation === 'parallax scrolling'
        ? <div id={`bx-${genID}`} data-background-content>
                {
                section.tile[0] && section.tile[0].content
                ? <div data-blocktype="single-card">{renderContent(section.internal.type, 'full width', section.tile[0].content ? section.tile[0].content : '')}</div>
                : ''
                }
                {
                section.tile[0] && section.tile[0].contentLeft
                ? <div data-blocktype="double-cards">{renderContent(section.internal.type, 'full width', section.tile[0].contentLeft ? section.tile[0].contentLeft : '')}</div>
                : ''
                }
                {
                section.tile[0] && section.tile[0].contentRight
                ? <div data-blocktype="double-cards">{renderContent(section.internal.type, 'full width', section.tile[0].contentRight ? section.tile[0].contentRight : '')}</div>
                : ''
                }
        </div>
        : ''
        }
        <div data-tiles id={`target-${genID}`}>
        {
        section.tile.map((tile, tileIndex) => (
            <div data-index={tileIndex} data-blockstyle={blockStyle}>
                {
                tile && tile.content
                ? <div data-blocktype="single-card">{renderContent(section.internal.type, 'full width', tile.content ? tile.content : '')}</div>
                : ''
                }
                {
                tile && tile.contentLeft
                ? <div data-blocktype="double-cards">{renderContent(section.internal.type, 'full width', tile.contentLeft ? tile.contentLeft : '')}</div>
                : ''
                }
                {
                tile && tile.contentRight
                ? <div data-blocktype="double-cards">{renderContent(section.internal.type, 'full width', tile.contentRight ? tile.contentRight : '')}</div>
                : ''
                }
            </div>
        ))
        }
        </div>
        </div>
        return Container;
    case 'ContentfulBlockAniParallaxScrolling':
        const style = (section.style && section.style.style) || false;
        animation = 'parallax scrolling';
        Container = <div className={section.internal.type} data-card
          data-width={section.blockWidth ? section.blockWidth.blockWidth.blockWidth : ''}
          data-blockalignment={blockAlignment}
          data-animation={animation}
          data-style={style}
          data-content="1"
          >
            {section && section.backgroundImage
            ?
            <div data-img-background>
                {
                section && section.backgroundImage && section.backgroundImage.desktopImage
                ? <img class="img desktop animated"
                src={section.backgroundImage.desktopImage.gatsbyImageData.images.fallback.src}
                srcset={section.backgroundImage.desktopImage.gatsbyImageData.images.fallback.srcSet}
                alt={section.backgroundImage ? section.backgroundImage.altText : ''}
                />
                : ''
                }
                {
                section && section.backgroundImage && section.backgroundImage.mobileImage
                ? <img class="img mobile animated"
                src={section.backgroundImage.mobileImage.gatsbyImageData.images.fallback.src}
                srcset={section.backgroundImage.mobileImage.gatsbyImageData.images.fallback.srcSet}
                alt={section.backgroundImage ? section.backgroundImage.altText : ''}
                />
                : section && section.backgroundImage && section.backgroundImage.desktopImage
                ? <img class="img mobile animated"
                src={section.backgroundImage.desktopImage.gatsbyImageData.images.fallback.src}
                srcset={section.backgroundImage.desktopImage.gatsbyImageData.images.fallback.srcSet}
                alt={section.backgroundImage ? section.backgroundImage.altText : ''}
                />
                : ''
                }
            </div>
              : <div data-background-color={true} id={'bx-bg-color'}></div>
            }
            <div data-background data-mobile>
                {renderContent(section.internal.type, 'full width', section.stickyContent ? section.stickyContent : '')}
            </div>
            <div data-heading>
            {section && section.heading
            ? <h3 data-title>{section.heading}</h3>
            : ''
            }
            </div>
            <div id={`bx-${genID}`} data-background-content>
                {renderContent(section.internal.type, (section.stickyContent && section.stickyContent.blockWidth) ? section.stickyContent.blockWidth.blockWidth.blockWidth : 'full width', section.stickyContent ? section.stickyContent : '')}
            </div>
            <div data-parallax-scrolling id={`target-${genID}`}>
            {
            section.scrollingContents.map((content, contentIndex) => (
                <div data-item data-index={contentIndex} data-blockstyle={blockStyle} className={'animated'}
                  data-background-color={content.backgroundColour}
                >
                    {
                    content
                    ? <div data-container-image-right={content.imagePosition?.imagePosition === 'Image Right'} data-container data-item-container id={`target-${genID}-${contentIndex}`}>
                    {renderContent(section.internal.type, 'full width', content ? content : '')}
                    </div>
                    : ''
                    }
                </div>
            ))
            }
            </div>
            {(style === 'content-left indicators-left' || style === 'content-right indicators-right' )
            ? <div data-indicators>
            {
            section.scrollingContents.map((content, contentIndex) => (
                <div id={`indicator-${genID}-${contentIndex}`} className={`btn indicator indicator-${contentIndex}`} data-indicator={contentIndex}><p><span class="txt">{`0${contentIndex+1}`}</span></p></div>
            ))
            }
            </div>
            : ''
            }
        </div>
        return Container;
    case 'ContentfulBlockButtons':
        blockWidth = section.blockWidth.blockWidth.blockWidth;
        const buttonClass = section.name.replace(/[^A-Z0-9]+/ig, "-").toLowerCase();
        Container = section.buttons.map((button, buttonIndex) => (
            <div>
            <span className={`btn btn-${buttonClass}-${buttonIndex} ${buttonIndex === 0 ? "active" : ""}`} 
            data-btnName={`btn-${buttonClass}`} 
            data-deeplink={button.deepLink}
            onClick={functionOnClick}
            aria-hidden='true'
            >
            {button.buttonText ? parse(gentHTML(button.buttonText)) : ''}
            </span>
            </div>
        ))
        Container = <div data-button-container data-width={blockWidth} data-block={blockStyle} style={{paddingTop: paddingTopBottom, paddingBottom: paddingTopBottom}}>
        {Container}
        </div>
        return Container;
    case 'ContentfulBlockContent':
        Container = 
        <div className={section.internal.type} data-card 
        data-width={section.blockWidth ? section.blockWidth.blockWidth.blockWidth : ''}
        >
        {renderContent(section.internal.type, 'full width', section)}
        </div>
        return Container;
    case 'ContentfulBlockProfiles':
    const buttonLoadMore = section.buttonLoadMore || false;

        Container = 
        <div className={`profiles-container profiles-container-${genID}`}>
            {
            section.profile.map((profile, profileIndex) => (
                <div id={`profile-${genID}-${profileIndex}`} 
                className={`bx-profile profile-${genID}-${profileIndex} ${buttonLoadMore === true && profileIndex > 3 ? 'hide' : ''}`} 
                data-direction={profile.blockDirection ? profile.blockDirection.blockDirection : ''}
                data-loadMore={buttonLoadMore}
                >
                    <div className={`bx-container bx-container-${genID}`}>
                        <div data-img-profile>
                            {profile && profile.image ? renderImages('', 'btn-profile', 'zoom-in', profile.image) : '' }
                            <div className={`btn-container btn-${genID} btn btn-cta btn-purple btn-5-hover`} 
                            data-addClass={`profile-poup-${genID}-${profileIndex}`} onClick={addClass}
                            aria-hidden='true'>
                            <span className={'txt'}>Profile</span>
                            </div>
                        </div>
                        <div className={`bx-content bx-${genID}-${profileIndex}`}>
                            {profile && profile.shortText
                            ? <div className={`bx-shorttext`}>
                            <div className="icon-container"><Icon symbol="iconDoubleQuptes" /></div>
                            {parse(gentHTML(profile.shortText.childMarkdownRemark.html))}
                            </div>
                            : ''
                            }
                            <div className={`bx-name-role`}>
                            {profile && profile.name ? <p data-name>{parse(gentHTML(profile.name))}</p> : ''}
                            {profile && profile.role ? <p data-role>{parse(gentHTML(profile.role))}</p> : ''}
                            </div>
                        </div>
                    </div>
                    <div id={`profile-poup-${genID}-${profileIndex}`} className={`modal-container profile-poup-${genID}-${profileIndex}`}>
                    <div
                    className='bg dark'
                    data-removeClass={`profile-poup-${genID}-${profileIndex}`} 
                    onClick={removeClass}
                    aria-hidden='true'
                    ></div>
                        {profile && profile.fullProfileContent
                        ? 
                        <div className={`bx-profile-content bx-content`}>
                        <div className='bx-item'>
                            {profile && profile.image
                            ? <div data-img-profile>{renderImages('', 'btn-profile', 'zoom-in', profile.image)}</div>
                            : ''
                            }
                        <div className='icon-container'><Icon symbol="iconDoubleQuptes" /></div>
                        {parse(gentHTML(profile.fullProfileContent.childMarkdownRemark.html))}
                        </div>
                        <div className='btn btn-cta btn-close btn-green' 
                        data-removeClass={`profile-poup-${genID}-${profileIndex}`} 
                        onClick={removeClass} aria-hidden='true'>
                        </div>
                        </div>
                        : ''
                        }
                    </div>
                </div>
            ))
            }
            {(buttonLoadMore === true) && (section.profile.length > 3) 
            ? <div className='btn btn-cta btn-3 btn-grey btn-loadmore' data-loadmore={'bx-profile'} onClick={ButtonLoadMore} aria-hidden='true'>
            Load more
            </div>
            : ''
            }
        </div>
        return Container;
    case 'ContentfulBlockRadioContents':
    Container = 
    <div className={section.internal.type} data-card 
    data-width={section.blockWidth ? section.blockWidth.blockWidth.blockWidth : ''}
    >
    <div className='bx-radio-container' data-layout={section.style.style || false}>
        <div className='bx-content-container'>
            {section.heading ? <h3 data-title data-headingsize={(section.headingSize ? section.headingSize.headingSize: '')}>{section.heading}</h3> : ''}
            <div className='btn-container'>
            {(section.radioContent.map((dataContent, dataContentIndex) => (
                <div className={`btn btn-cta btn-radio btn-radio-${genID} ${dataContentIndex === 0? 'active': ''}`} 
                data-target={`bx-radio-${genID}-${dataContentIndex}`}
                data-radio={genID}
                onClick={ButtonRadio}
                aria-hidden='true'
                >
                <span className='txt'>{dataContent.name}</span>
                </div>
            ))
            )}
            </div>
        </div>
        <div className='bx-content-container'>
            {(section.radioContent.map((dataContent, dataContentIndex) => (
                <div className={`bx-item bx-radio-${genID} bx-radio-${genID}-${dataContentIndex} ${dataContentIndex === 0? 'show': 'hide'}`} data-contents>
                {renderContent(
                    section.internal.type, 
                    (dataContent.content.blockWidth ? dataContent.content.blockWidth.blockWidth.blockWidth : ''), 
                    (dataContent ? dataContent.content : '')
                )}
                </div>
            ))
            )}
        </div>
    </div>
    </div>
        return Container;
    case 'ContentfulBlockImage':
        return  <img src={section.desktopImage.file.url} alt=''/>
                    
    case 'ContentfulBlockIFrame':
    let src = section.src || false;
    const height = section.height || false;
    const source = section && section.source ? section.source.source : '';
//     const blockWidth = section.blockWidth && section.blockWidth.blockWidth ? section.blockWidth.blockWidth.blockWidth : '';

    const genIframeClass = section.name ? 'bx-iframe bx-iframe-' + section.name.trim().replace('.', '').replace(/[^A-Z0-9]+/ig, "-").toLowerCase() : '';
    Container = <div className={genIframeClass}>
        <div className='bx-header-container' 
//         data-width={blockWidth}
        data-alignment-content={section.textAlignment ? section.textAlignment.textAlignment : ''}
        >
        {section.heading ? <h3 data-title data-headingsize={(section.headingSize ? section.headingSize.headingSize: '')}>{section.heading}</h3> : ''}
        {section.subText ? <p data-text>{(section.subText && section.subText.subText) ? section.subText.subText : ''}</p> : ''}
        </div>
        <iframe 
        title={`iframe-${genIframeClass}`}
        className={genIframeClass}
        src={
        source === 'hubspot'
        ? src = `https://share.hsforms.com/${src}`
        : src
        } 
        style={{height: `${height}px`}}
        />
    </div>
        return Container;
    default:
      return '';
  }
}

export default renderSection